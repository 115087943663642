'use client'
import { usePathname } from 'next/navigation'
import { Alert, Container } from '@mui/material'

export default function ProfileNotFound() {
  const pathname = usePathname()

  return (
    <Container style={{ marginTop: 30 }}>
      <h1>Error </h1>
      <Alert severity='info' style={{ fontSize: 18 }}>
        The user&nbsp;
        <span style={{ fontWeight: 600 }}>{pathname.split('/')[1]}</span>&nbsp;
        doesn&apos;t exist. Try searching for another one.
      </Alert>
    </Container>
  )
}
